import IconButton from '@material-ui/core/IconButton';
import { PropTypes } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import RightsFilterPopper from '../../popper/RightsFilterPopper';

interface IRightsFilterButtonProps {
    id ?: string;
    open : boolean;
    name : string | null;
    employeeNr : string | null;
    isActive : boolean | null;
    disabled ?: boolean;
    color ?: PropTypes.Color;
    roleId : number | null;
    onChange ?: (
        name : string | null,
        employeeNr : string | null,
        isActive : boolean | null,
        roleId : number | null,
        roleName : string | null,
    ) => void;
    onClick ?: () => void;
}

interface IRightsFilterButtonState { }

export default class RightsFilterButton extends React.PureComponent<IRightsFilterButtonProps, IRightsFilterButtonState> {
    private filterRef : React.RefObject<HTMLButtonElement>;

    constructor(props : IRightsFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
        this.state = {};
    }

    public render = () => {
        const {
            id,
            onChange,
            onClick,
            color,
            name,
            employeeNr,
            disabled,
            open,
            isActive,
            roleId,
        } = this.props;

        return (
            <>
                <Tooltip title={'Filter'}>
                    <IconButton
                        ref={this.filterRef}
                        aria-label={'toggle filter menu'}
                        onClick={onClick}
                        disabled={disabled}
                        color={color}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                <RightsFilterPopper 
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={open}
                    name={name}
                    employeeNr={employeeNr}
                    onCancelClick={onClick}
                    roleId={roleId}
                    onChange={onChange}
                    isActive={isActive}
                />
            </>
        )
    }
}