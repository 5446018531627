import React from 'react';
import { IIncident, IIncidentDocket, IIncidentDocketFile } from '../../../../@types/model/incident';
import IncidentDocketHttpService from '../../../../service/http/incident/incidentDocketHttpService';
import GeneralFunctions from '../../../../store/general/functions';
import { CircularProgress, Icon, IconButton, InputAdornment, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import StandardFab from '../../custom/button/StandardFab';
import { CloudUpload, InfoRounded, Search, Share } from '@material-ui/icons';
import lodash from 'lodash';
import IncidentDocketFileDialog from '../dialog/docket/FileUpload';
import SortButton from '../../custom/button/SortButton';
import { createSelector } from 'reselect';
import moment from 'moment';
import IncidentDocketFileView from './DocketFileView';
import IncidentDocketShareDialog from '../dialog/docket/Share';
import { Transitions } from '../../custom/animations/Transitions';
import IncidentDocketInformationDialog from '../dialog/docket/Information';

interface IIncidentDocketViewProps {
    incident : IIncident;
    isEditAdmin : boolean;
    isSaving : boolean;
}

interface IIncidentDocketViewState {
    docket : IIncidentDocket | null;
    isLoading : boolean;

    files : Array<File>;

    search : string;
    alphabeticalSort : boolean;
    dateSort : boolean;
    openShare : boolean;
    openInfo : boolean;
}

export default class IncidentDocketView extends React.PureComponent<IIncidentDocketViewProps, IIncidentDocketViewState> {
    private readonly inputRef : React.RefObject<HTMLInputElement>;
    constructor(props : IIncidentDocketViewProps) {
        super(props);
        this.state = {
            docket: null,
            isLoading: false,
            files: [],
            search: '',
            alphabeticalSort: false,
            dateSort: false,
            openShare: false,
            openInfo: false,
        };

        this.inputRef = React.createRef();
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentWillUnmount() : void {
        IncidentDocketHttpService.cancelGet?.call(this);
    }

    private readonly load = async () => {
        this.setState({
            isLoading: true,
        });

        try {
            const result = await IncidentDocketHttpService.get(this.props.incident.id);

            if (result.status === 204) return;
            
            this.setState({
                docket: result.data,
            });
        } catch (ex) {
            GeneralFunctions.showErrorSnackbar('Error loading Docket.', ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }

    };

    private readonly onFileUploadClick = () => {
        this.inputRef.current?.click();
    };

    private readonly onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files?.length) return;
        
        this.setState({
            files: lodash.map(event.currentTarget.files, (file) => new File([file.slice()], `${file.name}`, {
                type: file.type,
            })),
        });
    };

    private readonly onUploadClose = (docket ?: IIncidentDocket) => {
        this.setState({
            files: [],
            docket: docket ?? this.state.docket,
        });
    };

    private readonly onShareClick = () => {
        this.setState({
            openShare: true,
        });
    };

    private readonly onShareClose = (docket ?: IIncidentDocket) => {
        this.setState({
            openShare: false,
            docket: docket ?? this.state.docket,
        });
    };

    private readonly onInfoClick = () => {
        this.setState({
            openInfo: true,
        });
    };

    private readonly onInfoClose = () => {
        this.setState({
            openInfo: false,
        });
    };

    private readonly onSearchChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            search: event.currentTarget.value,
        });
    };

    private readonly onSortChange = (
        alphabetical : boolean,
        date : boolean,
    ) => {
        this.setState({
            alphabeticalSort: alphabetical,
            dateSort: date,
        });
    };

    private readonly getDocket = (state : IIncidentDocketViewState) => state.docket;
    private readonly getSearch = (state : IIncidentDocketViewState) => state.search;
    private readonly getAlphabeticalSort = (state : IIncidentDocketViewState) => state.alphabeticalSort;
    private readonly getDateSort = (state : IIncidentDocketViewState) => state.dateSort;
    
    private readonly getAllFiles = createSelector(
        [
            this.getDocket,
            this.getSearch,
            this.getAlphabeticalSort,
            this.getDateSort,
        ],
        (
            docket,
            search,
            alphabeticalSort,
            dateSort,
        ) => {
            return lodash
                .chain(docket?.files ?? [])
                .filter(x => x.isActive)
                .filter(x => !search
                    || moment.utc(x.date).format('DD MMM YYYY').toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || !!x.file?.fileName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                .sortBy(x => {
                    if (alphabeticalSort) return x.file?.fileName;
                    if (dateSort) return moment.utc(x.date).valueOf();

                    return '';
                })
                .value();
        },
    );
    
    private readonly getRecentFiles = createSelector(
        [
            this.getAllFiles,
        ],
        (
            files,
        ) => {
            return lodash
                .chain(files)
                .filter(x => moment.utc(x.date).diff(moment.utc(), 'days') < 7)
                .value();
        },
    );

    private readonly delete = async (docketFile : IIncidentDocketFile) => {
        this.setState({
            isLoading: true,
        });

        try {
            const result = await IncidentDocketHttpService.deleteFile(docketFile);

            if (result.status === 204) return;
            
            this.setState({
                docket: result.data,
            });
        } catch (ex) {
            GeneralFunctions.showErrorSnackbar('Error deleting Docket file.', ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    private readonly onDelete = (docketFile : IIncidentDocketFile) => {
        this.delete(docketFile);
    };

    private readonly saveFile = async (docketFile : IIncidentDocketFile) => {
        this.setState({
            isLoading: true,
        });

        try {
            const result = await IncidentDocketHttpService.saveFile(docketFile);

            if (result.status === 204) return;
            
            this.setState({
                docket: result.data,
            });
        } catch (ex) {
            GeneralFunctions.showErrorSnackbar('Error updating Docket file.', ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    }

    private readonly onRename = (docketFile : IIncidentDocketFile) => {
        this.saveFile(docketFile);
    };
    
    public readonly render = () => {
        const { isEditAdmin, incident } = this.props;
        const { isLoading, docket, files,
            alphabeticalSort,
            dateSort,
            openShare,
            openInfo,
         } = this.state;

         const allFiles = this.getAllFiles(this.state);
         const recentFiles = this.getRecentFiles(this.state);

        return (
            <div className='flx1 fdc pt20 pb20 bcg1'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !docket &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className='fs30'>No docket information found.</Typography>
                    </div>
                }
                {
                    !isLoading &&
                    docket &&
                    <div className='fdc flx1 ais jcfs'>
                        <Toolbar className='fdr'>
                            <Typography className='fwm fs18 cpd'>Recent Files</Typography>
                            <span className='flx1' />
                            <div className='fdc aic jcc mr15'>
                                <SortButton
                                    alphabetical={alphabeticalSort}
                                    date={dateSort}
                                    onChange={this.onSortChange}
                                />
                            </div>
                            <div className='fdc aic jcc ml15'>
                                <TextField
                                    className='fs14 cg2'
                                    onChange={this.onSearchChange}
                                    InputProps={{
                                        placeholder: 'Search',
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Search className='cg2' />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </Toolbar>
                        <div className='fdr pl24 pr24'>
                            {
                                !recentFiles.length &&
                                <div className='fdc flx1 aic jcc'>
                                    <Typography className='fs30'>No recent files.</Typography>
                                </div>
                            }
                            {
                                !!recentFiles.length &&
                                <IncidentDocketFileView
                                    docketFiles={recentFiles}
                                    disabled={!isEditAdmin}
                                    onDelete={this.onDelete}
                                    onRename={this.onRename}
                                />
                            }
                        </div>
                        <Toolbar className='fdr'>
                            <Typography className='fwm fs18 cpd'>All Files</Typography>
                            <span className='flx1' />
                        </Toolbar>
                        <div className='fdr pl24 pr24'>
                            {
                                !allFiles.length &&
                                <div className='fdc flx1 aic jcc'>
                                    <Typography className='fs30'>No recent files.</Typography>
                                </div>
                            }
                            {
                                !!allFiles.length &&
                                <IncidentDocketFileView
                                    docketFiles={allFiles}
                                    disabled={!isEditAdmin}
                                    onDelete={this.onDelete}
                                    onRename={this.onRename}
                                />
                            }
                        </div>
                    </div>
                }
                <div className='fdr posr'>
                    <div className='posa posb0 posr0'>
                        <div className='fdc'>
                            <div className='mt15'>
                                <Tooltip title='Docket Information'>
                                    <div>
                                        <StandardFab
                                            color='primary'
                                            aria-label='Docket Information'
                                            disabled={isLoading || !docket}
                                            position={'relative'}
                                            onClick={this.onInfoClick}
                                        >
                                            <InfoRounded />
                                        </StandardFab>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className='mt15'>
                                <Tooltip title='Upload Files'>
                                    <div>
                                        <StandardFab
                                            color='primary'
                                            aria-label='Upload Files'
                                            disabled={isLoading || !isEditAdmin}
                                            onClick={this.onFileUploadClick}
                                            position={'relative'}
                                        >
                                            <CloudUpload />
                                        </StandardFab>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className='mt15'>
                                <Tooltip title='Share Docket'>
                                    <div>
                                        <StandardFab
                                            aria-label='Share Docket'
                                            disabled={isLoading || !docket}
                                            onClick={this.onShareClick}
                                            position={'relative'}
                                        >
                                            <Share />
                                        </StandardFab>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <input
                        ref={this.inputRef}
                        hidden
                        disabled={isLoading}
                        multiple={true}
                        onChange={this.onFileChange}
                        type='file'
                        accept='application/pdf,image/*'
                    />
                </div>
                <IncidentDocketFileDialog
                    files={files}
                    fullWidth
                    maxWidth='md'
                    onClose={this.onUploadClose}
                    incident={incident}
                    docket={docket}
                />
                {
                    docket &&
                    <IncidentDocketShareDialog
                        fullWidth
                        open={openShare}
                        maxWidth='md'
                        onClose={this.onShareClose}
                        incident={incident}
                        docket={docket}
                        transition={Transitions.Down}
                    />
                }
                {
                    docket &&
                    <IncidentDocketInformationDialog
                        fullWidth
                        open={openInfo}
                        maxWidth='md'
                        onClose={this.onInfoClose}
                        docket={docket}
                        transition={Transitions.Down}
                    />
                }
            </div>
        );
    }
}
