import axios, { AxiosPromise } from 'axios';
import { ILatLng } from '../../@types/model/base';
import { IDivision } from '../../@types/model/division';
import { IObservation, IObservationFile } from '../../@types/model/observation';
import { IFileUpload } from '../../@types/viewmodel/file';

export default class ObservationHttpService {

    public static get(id? : number, guid? : string) : AxiosPromise<IObservation> {
        return axios.get(`${API_URL}/api/Observations/Observation/Get`, {
            params: {
                id,
                guid,
            },
        });
    }

    public static getAll(startDateUnixMilli : number, endDateUnixMilli : number) : AxiosPromise<Array<IObservation>> {
        return axios.get(`${API_URL}/api/Observations/Observation/GetAll`, {
            params: {
                startDateUnixMilli,
                endDateUnixMilli,
            },
        });
    }

    public static create(guid : string, date : number, division : IDivision, description : string, geo : ILatLng, files : Array<IFileUpload>) {
        return axios.post<IObservation>(`${API_URL}/api/Observations/Observation/Create`, {
            guid,
            division,
            description,
            date,
            files,
            geo,
        }, {
            params: {
            },
        });
    }

    public static update(guid : string, date : number, division : IDivision, description : string, geo : ILatLng, files : Array<IFileUpload>) {
        return axios.post<IObservation>(`${API_URL}/api/Observations/Observation/Update`, {
            guid,
            division,
            description,
            date,
            files,
            geo,
        }, {
            params: {
            },
        });
    }

    public static remove(observation : IObservation) {
        return axios.delete(`${API_URL}/api/Observations/Observation/Delete`, {
            params: {
                guid: observation.guid,
            },
        });
    }
}
