import axios, { AxiosPromise, CancelTokenStatic, Canceler } from 'axios';
import lodash from 'lodash';
import { IIncident, IIncidentFile } from '../../../@types/model/incident';
import { IIncidentGeneralInfoCapture, IIncidentSAPSCapture,
    IIncidentComplainantCapture, IIncidentCapture, IIncidentEvidence, IIncidentSuspects, IIncidentUpdateSave, IIncidentCourtCaseCreate } from '../../../@types/viewmodel/incident';

export default class IncidentHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;

    /**
     * Allows you to cancel current get list request
     */
    private static cancelGetList? : Canceler;

    public static save(incident : IIncident) {
        return axios.patch<IIncident>(`${API_URL}/api/Incidents/Incident/Save`, incident, {
            params: {},
        });
    }

    public static saveUpdate(incidentUpdate : IIncidentUpdateSave) {

        const cloned = lodash.cloneDeep(incidentUpdate);
        // Clear files as we don't want to send them.
        cloned.files.forEach(n => n.file = null);

        return axios.patch<IIncident>(`${API_URL}/api/Incidents/Incident/SaveUpdate`, cloned, {
            params: {
            },
        });
    }

    public static remove(incident : IIncident) {
        return axios.delete(`${API_URL}/api/Incidents/Incident/Delete`, {
            params: {
                guid: incident.guid,
            },
        });
    }

    public static get(id? : number, guid? : string) : AxiosPromise<IIncident> {
        return axios.get(`${API_URL}/api/Incidents/Incident/Get`, {
            params: {
                id,
                guid,
            },
        });
    }

    public static getAll(startDateUnixMilli : number, endDateUnixMilli : number, division? : string) : AxiosPromise<Array<IIncident>> {
        if (this.cancelGetList) {
            this.cancelGetList();
        }

        return axios.get(`${API_URL}/api/Incidents/Incident/GetAll`, {
            cancelToken: new this.CancelToken((c) => {
                this.cancelGetList = c;
            }),
            params: {
                startDateUnixMilli,
                endDateUnixMilli,
                division,
            },
        });
    }

    /**
     * Creates incident, but does not upload files.
     *
     * @export
     * @param {IIncidentGeneralInfoCapture} generalInfo
     * @param {IIncidentSAPSCapture} saps
     * @param {IIncidentComplainantCapture} complainant
     * @param {IIncidentCapture} incident
     * @param {IIncidentEvidence} evidence
     * @param {IIncidentSuspects} suspects
     * @returns
     */
    public static create(
        guid : string,
        generalInfo : IIncidentGeneralInfoCapture,
        saps : IIncidentSAPSCapture,
        complainant : IIncidentComplainantCapture,
        incident : IIncidentCapture,
        evidence : IIncidentEvidence,
        suspects : IIncidentSuspects,
        courtCase ?: IIncidentCourtCaseCreate | null,
    ) {

        // Clear files as we don't want to send them.
        incident.incidentFiles.forEach(n => n.file = null);
        evidence.evidenceFiles.forEach(n => n.file = null);
        suspects.suspectFiles.forEach(n => n.file = null);

        return axios.post<IIncident>(`${API_URL}/api/Incidents/Incident/Create`, {
            guid,
            generalInfo,
            saps,
            complainant,
            incident,
            evidence,
            suspects,
            courtCase,
        }, {
            params: {
            },
        });
    }

    public static setApproval(guids : Array<string>, approve : boolean) : AxiosPromise<Array<IIncident>> {
        if (this.cancelGetList) {
            this.cancelGetList();
        }

        if (approve) return axios.post(`${API_URL}/api/Incidents/Incident/Approve`, guids);

        return axios.post(`${API_URL}/api/Incidents/Incident/Disapprove`, guids);
    }
}
