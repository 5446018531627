import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { IIncident, IIncidentDocket, IIncidentDocketUser } from '../../../../../@types/model/incident';
import IncidentDocketHttpService from '../../../../../service/http/incident/incidentDocketHttpService';
import GeneralFunctions from '../../../../../store/general/functions';
import UserAutocomplete from '../../../custom/autocomplete/UserAutocomplete';
import { IUserListView } from '../../../../../@types/model/right';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../@types/redux';
import IncidentDocketPeopleView from '../../view/docket/PeopleVIew';
import { ROLES } from '../../../../../appConstants';

interface IIncidentDocketShareDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    open ?: boolean;
    onClose ?: (docket ?: IIncidentDocket) => void;

    incident : IIncident;
    docket : IIncidentDocket;
}

interface IIncidentDocketShareDialogState {
    isLoading : boolean;

    users : Array<IIncidentDocketUser>;
}

class IncidentDocketShareDialogComponent extends React.PureComponent<IIncidentDocketShareDialogProps, IIncidentDocketShareDialogState> {
    constructor(props : IIncidentDocketShareDialogProps) {
        super(props);
        this.state = {
            users: [],
            isLoading: false,
        };
    }
    public componentDidUpdate(prevProps : Readonly<IIncidentDocketShareDialogProps>): void {
        if (!prevProps.open && this.props.open) {
            this.setState({
                users: this.props.docket.users?.slice(),
            });
        }
    }
    
    public readonly onClose = () => {
        if (this.state.isLoading) return;
        if (!this.props.onClose) return;

        this.props.onClose();
    };
    
    public readonly onOkClick = () => {
        this.save();
    };
    
    public readonly save = async () => {
        this.setState({
            isLoading: true,
        });

        const docket = {
            ...this.props.docket,
            users: [...this.state.users]
        } as IIncidentDocket;

        try {
            const result = await IncidentDocketHttpService.save(docket, []);
            if (!this.props.onClose) return;
    
            this.props.onClose(result.data);
        } catch (ex) {
            GeneralFunctions.showErrorSnackbar('Error saving docket.', ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };
    
    public readonly onUserChange = (user : IUserListView | null) => {
        if (!user) return;
        this.setState({
            users: [
                ...this.state.users, {
                    userId: user.id,
                    user,
                } as unknown as IIncidentDocketUser
            ]
        })
    };
    
    public readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const users = this.state.users.slice();

        users.splice(Number(event.currentTarget.value), 1);

        this.setState({
            users,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, open } = this.props;
        const { isLoading, users } = this.state;
        return (
            <Dialog
                open={open ?? false}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='tooltip-dialog-title'
                aria-describedby='tooltip-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            Upload Selected Shares?
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill p15'>
                    <div className='fdc'>
                        <UserAutocomplete
                            onlyEmail
                            label='Add People'
                            onChange={this.onUserChange}
                            value={null}
                            getOptionDisabled={(option) => users.some((user) => user.userId === option.value)}
                            roleId={ROLES.MANAGER}
                        />
                    </div>
                    <div className='p12 bcg1 mt15'>
                        <Typography className='fs16 fwm cpd'>PEOPLE WITH ACCESS</Typography>
                    </div>
                    <IncidentDocketPeopleView
                        users={users}
                        onDeleteClick={this.onDeleteClick}
                        disabled={isLoading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={this.onOkClick} type='button' variant='contained' color='primary'>
                        DONE
                    </Button>
                    <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps = (state : IRootState) => {
    return {
    };
};

const IncidentDocketShareDialog = connect(
    mapStateToProps,
)(IncidentDocketShareDialogComponent);

export default IncidentDocketShareDialog;