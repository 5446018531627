import { IBaseModel } from './base';

export interface IUserToken {
    id : number;
    userId : number;
    user : IUser;
    expirationDate : Date;
    token : string;
    isActive : boolean;
    createdOn : string;
    updatedOn : string;
}

export interface IUser {
    id : number;
    name : string;
    email : string;
    employeeNr? : string;
    profilePictureURL : string;
    isActive : boolean;
    lastLogin : string;
    createdById : number | null;
    createdByName : string | null;
    createdOn : string;
    updatedById : number | null;
    updatedByName : string | null;
    updatedOn : string;

    userNotificationSubscriptions : Array<IUserNotificationSubscriptions>;

    roleId : number | null;
    roleCode : string | null;
    roleName : string | null;
    userRights : Array<IUserRight>;
    userDivisions : Array<IUserDivision>;
    userAccessPoints : Array<IUserAccessPoints>;
}

export interface IUserRight extends IBaseModel {
    rightId : number;
    userId : number;
    right ?: IRight;
}

export interface IUserDivision extends IBaseModel {
    divisionCode : string;
    divisionGL : string;
    userId : number;
}

export interface IUserAccessPoints extends IBaseModel {
    accessPointId : number;
    userId : number;
}

export interface IUserListView {
    id : number;
    name : string;
    email : string;
    employeeNr? : string;
    roleId : number | null;
    role : string | null;
    isActive : boolean;
    createdById : number | null;
    createdByName : string | null;
    createdOn : string;
    updatedById : number | null;
    updatedByName : string | null;
    updatedOn : string;
}

export interface IUserNotificationSubscriptions extends IBaseModel {
    notificationTypeId : number;
    notificationType : INotificationType | null;
    user : IUser | null;
    userId : number;
    fCMToken : string;
}

export interface INotificationType {
    id : number;
    code : string;
    description : string;
}

export interface IRole extends IBaseModel {
    code : string;
    name : string;
    createdByName : string;
    updatedByName : string;
    roleRights : Array<IRoleRight>;
}

export interface IRoleRight {
    rightId : number;
    roleId : number;
    right? : IRight;
}

export interface IRight extends IBaseModel {
    parentId? : number;
    code : string;
    name : string;
    url : string;
    isMenu : boolean;
    type : number;
}

export interface IMappedRight {
    id : number;
    code : string;
    name : string;
    url : string;
    isMenu : boolean;
    children : Array<IMappedRight>;
}

export enum EnumRightType {
    Incident = 0,
    Observation = 1,
    Right = 3,
    AccessPointEvent = 4,
    Master = 5,
    Mobile = 6,
    GraveSite = 7,
}
