import { IRightsState } from '../../../../store/right/reducer';
import { IRole } from '../../../../@types/model/right';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { IRootState } from '../../../../@types/redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import RightsFunctions from '../../../../store/right/functions';

interface IRoleDropdownProps {
    required? : boolean;

    roles : Array<IRole>;
    isLoading : boolean;

    roleId : number | null;

    onSelected : (role : IRole) => void;
    fullWidth? : boolean;
}

interface IRoleDropdownState {
}

class RoleDropdown extends React.Component<IRoleDropdownProps, IRoleDropdownState> {

    constructor(props : IRoleDropdownProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount = () => {
        this.loadData();
    }

    public loadData = () => {
        RightsFunctions.getAllRoles(undefined, undefined, true);
    }

    private onChanged = (event : React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        const result = this.props.roles.find(n => n.id === Number(event.target.value));

        if (!!result) this.props.onSelected(result);
    }

    public render() {
        const { roles, isLoading, roleId, fullWidth } = this.props;
        return (
            <FormControl fullWidth>
                <InputLabel htmlFor='action'>Role</InputLabel>
                <Select className={'DropDown mb12'}
                    inputProps={{
                        id: 'action',
                    }}
                    error={!roleId && !!this.props.required}
                    value={!roleId || !roles.length ? '' : `${roleId}`}
                    onChange={this.onChanged}
                    fullWidth={fullWidth}
                >
                    {
                        roles.map((n) => {
                            return (
                                <MenuItem key={`${n.id}_role_drop`} value={n.id} >
                                    <span>{n.name}</span>
                                </MenuItem>);
                        })
                    }
                </Select>
                {
                    isLoading &&
                    <LinearProgress />
                }
                {
                    !roleId && this.props.required && (
                        <FormHelperText error>Required</FormHelperText>
                    )
                }
            </FormControl>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        roles: state.rights.roles,
        isLoading: state.rights.isLoadingRoles,
    };
};

export default connect(
    mapStateToProps,
)(RoleDropdown);
