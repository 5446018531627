import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions, Typography } from '@material-ui/core';
import { IIncident, IIncidentDocket, IIncidentDocketUser } from '../../../../../@types/model/incident';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../@types/redux';
import IncidentDocketPeopleView from '../../view/docket/PeopleVIew';
import moment from 'moment';

interface IIncidentDocketInformationDialogProps {
    fullWidth? : boolean;
    maxWidth? : 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen? : boolean;
    transition? : React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    open ?: boolean;
    onClose ?: () => void;

    docket : IIncidentDocket;
}

interface IIncidentDocketInformationDialogState {
}

class IncidentDocketInformationDialogComponent extends React.PureComponent<IIncidentDocketInformationDialogProps, IIncidentDocketInformationDialogState> {
    constructor(props : IIncidentDocketInformationDialogProps) {
        super(props);
        this.state = {
        };
    }
    
    public readonly onClose = () => {
        if (!this.props.onClose) return;

        this.props.onClose();
    };
    
    public readonly onOkClick = () => {
        this.onClose();
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, open, docket } = this.props;
        
        return (
            <Dialog
                open={open ?? false}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='tooltip-dialog-title'
                aria-describedby='tooltip-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h6' color='inherit' className='flx1'>
                            Upload Selected Informations?
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill p15'>
                    <div className='fdr'>
                        <div className='fdc flx1'>
                            <Typography className='fs12 cg2'>Docket Created By</Typography>
                            <Typography className='fs16 cpd'>{docket.createdByName}</Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className='fs12 cg2'>Docket Created On</Typography>
                            <Typography className='fs16 cpd'>{moment.utc(docket.createdOn).local().format('DD/MM/YYYY')}</Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className='fs12 cg2'>Total Files</Typography>
                            <Typography className='fs16 cpd'>{docket.files.length}</Typography>
                        </div>
                    </div>
                    <div className='p12 bcg1 mt15'>
                        <Typography className='fs16 fwm cpd'>PEOPLE WITH ACCESS</Typography>
                    </div>
                    <IncidentDocketPeopleView
                        users={docket.users}
                        disabled
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onOkClick} type='button' variant='contained' color='primary'>
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps = (state : IRootState) => {
    return {
    };
};

const IncidentDocketInformationDialog = connect(
    mapStateToProps,
)(IncidentDocketInformationDialogComponent);

export default IncidentDocketInformationDialog;